import { AnimatePresence } from "framer-motion";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../assets/svg/arrow-up-right.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { LINKS } from "../../../constants/links";
import { PageEndpointEnum } from "../../../enums/endpoint";
import { useIntercomHook } from "../../../hooks/intercom";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import AboutAevoModal from "../Header/AboutAevoModal";
import { Container, NavItem, NavItems, SubNavItem } from "./style";
import { COMPONENTS } from "../../../constants/design/spacing";

interface IMobileNavigationMenuProps {
  open: boolean;
  onClose: () => void;
}

function MobileNavigationMenu({ open, onClose }: IMobileNavigationMenuProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "shared.MobileNavigationMenu",
  });
  const [showAboutAevoModal, setShowAboutAevoModal] = useState(false);
  const { handleIntercomButtonClicked } = useIntercomHook();

  const onIntercomButtonClicked = useCallback(() => {
    onClose();
    handleIntercomButtonClicked();
  }, [handleIntercomButtonClicked, onClose]);

  const openURL = useCallback(
    (url: string) => {
      onClose();
      window.open(url);
    },
    [onClose]
  );

  return (
    <AnimatePresence>
      <AboutAevoModal
        show={showAboutAevoModal}
        onHide={() => setShowAboutAevoModal(false)}
      />

      {open && (
        <Container
          key={"container"}
          style={{ position: "fixed" }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <div>
            <Button
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              onClick={onClose}
              style={{
                minWidth: COMPONENTS.header.buttons,
                minHeight: COMPONENTS.header.buttons,
                padding: 0,
              }}
            >
              <Close />
            </Button>
          </div>
          <NavItems>
            <NavItem>
              <button type="button" onClick={() => openURL(LINKS.home)}>
                {t("home")} <Arrow />
              </button>
            </NavItem>
            <NavItem>
              <button type="button" onClick={() => openURL(LINKS.dashboard)}>
                {t("dashboard")} <Arrow />
              </button>
            </NavItem>
            <NavItem>
              <button type="button" onClick={() => openURL(LINKS.apidocs)}>
                {t("api_reference")} <Arrow />
              </button>
            </NavItem>
            <NavItem>
              <button type="button" onClick={onIntercomButtonClicked}>
                {t("help_and_support")} <Arrow />
              </button>
            </NavItem>
            <NavItem>
              <button type="button" onClick={() => openURL(LINKS.terms)}>
                {t("terms")} <Arrow />
              </button>
            </NavItem>
            <NavItem>
              <Link to={PageEndpointEnum.HISTORICAL} onClick={onClose}>
                {t("historical_data")}
              </Link>
            </NavItem>
            <NavItem>
              <Link to={PageEndpointEnum.REFERRALS} onClick={onClose}>
                {t("referrals")}
              </Link>
            </NavItem>
            <NavItem>
              <Link to={PageEndpointEnum.LEADERBOARD} onClick={onClose}>
                {t("leaderboard")}
              </Link>
            </NavItem>
            <NavItem>
              <Link to={PageEndpointEnum.CAMPAIGNS} onClick={onClose}>
                {t("campaigns")}
              </Link>
            </NavItem>
            <NavItem>
              <Link to={PageEndpointEnum.OTC} onClick={onClose}>
                {t("otc")}
              </Link>
            </NavItem>
          </NavItems>

          <NavItems>
            <SubNavItem onClick={() => openURL(LINKS.twitter)}>
              {t("twitter")} <Arrow />
            </SubNavItem>
            <SubNavItem onClick={() => openURL(LINKS.discord)}>
              {t("discord")} <Arrow />
            </SubNavItem>
            <SubNavItem onClick={() => openURL(LINKS.github)}>
              {t("github")} <Arrow />
            </SubNavItem>
            <SubNavItem onClick={() => openURL(LINKS.blog)}>
              {t("blog")} <Arrow />
            </SubNavItem>
            <SubNavItem onClick={() => openURL(LINKS.mediakit)}>
              {t("media_kit")} <Arrow />
            </SubNavItem>
          </NavItems>
        </Container>
      )}
    </AnimatePresence>
  );
}
export default MobileNavigationMenu;

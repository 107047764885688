// Used as reference when handling useForm validate errors
export enum FormValidatorKeysEnum {
  required = "required",
  decimalsTooSmall = "decimalsTooSmall",
  moreThanZero = "moreThanZero",
  notEnoughBalance = "notEnoughBalance",
  orderValueTooSmall = "orderValueTooSmall",
  orderValueTooLarge = "orderValueTooLarge",
  orderNotionalTooLarge = "orderNotionalTooLarge",
  limitPriceAboveTriggerPrice = "limitPriceAboveTriggerPrice",
  limitPriceBelowTriggerPrice = "limitPriceBelowTriggerPrice",
  triggerPriceAboveMark = "triggerPriceAboveMark",
  triggerPriceBelowMark = "triggerPriceBelowMark",
  reduceOnlyOrderSizeValid = "reduceOnlyOrderSizeValid",
  reduceOnlyOrderSizeValidWithExistingOrder = "reduceOnlyOrderSizeValidWithExistingOrder",
  roiTooHigh = "roiTooHigh",
  roiTooLow = "roiTooLow",
  positionAmountGreaterThanPosition = "position amount cannot be greater than position",
  bothFieldsMustBeFilled = "trigger price and size must be filled",
  insufficientPremium = "insufficient premium",
}

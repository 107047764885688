import { AxiosError } from "axios";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import debounce from "lodash.debounce";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";
import { GetMargin200Response } from "../../../codegen-api";

interface IGetMarginPayload {
  is_buy: boolean;
  limit_price: string;
  amount: string;
  instrument_id: number;
}

export const useMarginRequirements = (
  params?: IGetMarginPayload,
  order_id?: string
) => {
  const { apiKey, apiSecret } = useContext(AuthContext);

  const [debouncedSearch, setDebouncedSearch] = useState(params);

  const fetcher = useMemo(
    () => authApi(apiKey, apiSecret),
    [apiKey, apiSecret]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceParams = useCallback(
    debounce((param?: IGetMarginPayload) => {
      setDebouncedSearch(param);
    }, 300),
    []
  );

  useEffect(() => {
    const isSameParams = Boolean(
      debouncedSearch?.is_buy === params?.is_buy &&
        debouncedSearch?.limit_price === params?.limit_price &&
        debouncedSearch?.amount === params?.amount &&
        debouncedSearch?.instrument_id === params?.instrument_id
    );

    if (params && !isSameParams) {
      debounceParams(params);
    }
  }, [params, debounceParams, debouncedSearch]);

  return useSWR<GetMargin200Response, AxiosError>(
    apiKey &&
      debouncedSearch &&
      debouncedSearch.limit_price &&
      Number(debouncedSearch.amount) &&
      debouncedSearch.instrument_id &&
      debouncedSearch.is_buy !== undefined
      ? [
          apiKey,
          APIEndpointEnum.MARGIN,
          debouncedSearch.limit_price,
          debouncedSearch.amount,
          debouncedSearch.instrument_id,
          debouncedSearch.is_buy,
          order_id,
        ]
      : undefined,
    async () =>
      (
        await (
          await fetcher.getMargin(
            debouncedSearch!.instrument_id,
            debouncedSearch!.is_buy,
            String(Number(debouncedSearch!.limit_price) * 10 ** 6),
            String(Number(debouncedSearch!.amount) * 10 ** 6),
            order_id
          )
        )()
      ).data
  );
};

import { AxiosError } from "axios";
import { ethers } from "ethers";
import { useCallback, useContext, useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import {
  GenericErrorResponse,
  GetL1Capacity200Response,
  GetWithdrawFees200Response,
  PostWithdraw200Response,
  PostWithdrawPayload,
} from "../../../codegen-api";
import {
  AllCollaterals,
  CONTRACT_ADDRESSES,
  DepositWithdrawCollaterals,
  MULTICHAIN_CONTRACT_ADDRESSES,
  getSocketConnectorAddress,
} from "../../../constants/addresses";
import { AuthContext } from "../../../contexts/AuthContext";
import { ChainIdEnum } from "../../../enums/chain";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";
import { getDomainData } from "../../../utils/signing";
import useDepositERC20OrETH from "../../contracts/useCollateralAssetOrETH";
import { IWallet } from "../../wallet/useWallet";
import useSocket from "../../contracts/useSocket";

const withdrawType = [
  { name: "collateral", type: "address" },
  { name: "to", type: "address" },
  { name: "amount", type: "uint256" },
  { name: "salt", type: "uint256" },
  { name: "data", type: "bytes32" },
];

const transferType = [
  { name: "collateral", type: "address" },
  { name: "to", type: "address" },
  { name: "amount", type: "uint256" },
  { name: "salt", type: "uint256" },
];

interface IWithdrawMessage {
  collateral: string;
  to: string;
  amount: string;
  salt: string;
  data: string;

  // For socket withdrawals
  socket_fees?: string;
  socket_msg_gas_limit?: string;
  socket_connector?: string;
}

interface ITransferMessage {
  collateral: string;
  to: string;
  amount: string;
  salt: string;
  label?: string;
  from?: string;
}

type IWithdrawERC20MultichainResponse =
  | {
      // Limit in asset terms, eg. 100 USDC
      insufficientLimit: string;
      response: undefined;
    }
  | {
      insufficientLimit: undefined;
      response: PostWithdraw200Response;
    };

export const useWithdrawalTransfer = (
  wallet: IWallet,
  asset: AllCollaterals,
  destinationChainId?: ChainIdEnum
) => {
  const { account, provider, chainId: connectedChainId } = wallet;
  const { apiKey, apiSecret } = useContext(AuthContext);

  const chainId = destinationChainId || connectedChainId;
  const { estimateMinFeesWithdrawals } = useSocket({ provider, chainId });

  const depositAsset = useDepositERC20OrETH(wallet, asset);

  const { l1Contract, l2Address, decimals } = depositAsset;

  const fetcher = useMemo(() => authApi(), []);

  const { data: l1WithdrawalCapacities } = useSWRImmutable<
    GetL1Capacity200Response[],
    AxiosError
  >(
    [APIEndpointEnum.L1_CAPACITY],
    async () => (await (await fetcher.getL1Capacity())()).data
  );

  const { data: withdrawalFees } = useSWRImmutable<
    GetWithdrawFees200Response[],
    AxiosError
  >(
    [APIEndpointEnum.WITHDRAWAL_FEES],
    async () => (await (await fetcher.getWithdrawFees())()).data
  );

  const createWithdrawal = useCallback(
    async (amountStr: string): Promise<IWithdrawERC20MultichainResponse> => {
      if (!account || !provider || !l1Contract || !chainId || !l2Address) {
        throw Error("Unauthenticated");
      }

      // if socket connector address, its using socket withdrawal
      const socketConnectorAddress = getSocketConnectorAddress(
        asset as DepositWithdrawCollaterals,
        "withdrawal",
        chainId
      );

      // Get signing keys, and sign message
      const salt = Math.floor(Math.random() * 100000);
      const amount = ethers.utils.parseUnits(amountStr, decimals);
      const to = socketConnectorAddress
        ? MULTICHAIN_CONTRACT_ADDRESSES[
            chainId as keyof typeof MULTICHAIN_CONTRACT_ADDRESSES
          ].l2WithdrawProxy
        : CONTRACT_ADDRESSES[chainId as ChainIdEnum]?.l2WithdrawProxy ??
          account;

      const withdrawMessage: IWithdrawMessage = {
        collateral: l2Address,
        to,
        amount: amount.toString(),
        salt: salt.toString(),
        data: ethers.utils.keccak256("0x"),
      };

      // Sign message and post payload
      const domainData = getDomainData(chainId);
      const providerSigner = provider.getSigner();

      // Add socket params if its a withdrawal from a supported socket chainId
      let socket_fees: string | undefined;
      let socket_msg_gas_limit: string | undefined;
      let socket_connector: string | undefined;

      // Socket withdrawal
      if (socketConnectorAddress) {
        // Check bridge capacity using deposit connector address. Returns early if not enough limit
        const { data: limitData } = await (await fetcher.getSocketCapacity())();
        const limit = limitData?.find(
          (l) => l.socket_connector === socketConnectorAddress
        )?.capacity;
        if (limit && Number(limit) < Number(amountStr)) {
          return {
            insufficientLimit: limit,
            response: undefined,
          };
        }

        // est fees
        const estFees = await estimateMinFeesWithdrawals(
          socketConnectorAddress
        );

        if (!estFees) {
          throw Error("No withdrawal fees");
        }

        // TEMPORARY until backend has a better way to dynamically update the minimum fees from the contract
        // It is currently hardcoded to 0.00003 ^ 10**18 in accounts/socket.go
        if (
          ethers.BigNumber.from(estFees.toString()).lt(
            ethers.utils.parseEther("0.00003")
          )
        ) {
          socket_fees = ethers.utils.parseEther("0.00003").toString();
        } else {
          socket_fees = estFees.toString();
        }

        socket_msg_gas_limit =
          chainId === ChainIdEnum.ARBITRUM ||
          chainId === ChainIdEnum.ARBITRUM_TESTNET
            ? "2000000"
            : "500000";
        socket_connector = socketConnectorAddress;

        const encodedSocketData = ethers.utils.defaultAbiCoder.encode(
          ["uint256", "uint256", "address"],
          [socket_fees, socket_msg_gas_limit, socket_connector]
        );

        withdrawMessage.data = ethers.utils.keccak256(encodedSocketData);
      } else {
        // Check l1 withdrawal capacity. Returns early if not enough limit
        const limit = l1WithdrawalCapacities?.find(
          (v) => v.collateral_asset === asset
        )?.capacity;
        if (limit && Number(limit) < Number(amountStr)) {
          return {
            insufficientLimit: limit,
            response: undefined,
          };
        }
      }

      const withdrawSignature = await providerSigner._signTypedData(
        domainData,
        { Withdraw: withdrawType },
        withdrawMessage
      );

      const withdrawPayload: PostWithdrawPayload = {
        account,
        signature: ethers.utils.joinSignature(
          ethers.utils.splitSignature(withdrawSignature)
        ),
        collateral: withdrawMessage.collateral,
        to: withdrawMessage.to,
        amount: withdrawMessage.amount,
        salt: withdrawMessage.salt,
        socket_fees,
        socket_msg_gas_limit,
        socket_connector,
      };

      try {
        const response = (await (await fetcher.postWithdraw(withdrawPayload))())
          .data;
        return {
          insufficientLimit: undefined,
          response,
        };
      } catch (error: any) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error || "Error withdrawing"
        );
      }
    },
    [
      account,
      provider,
      l1Contract,
      chainId,
      l2Address,
      asset,
      decimals,
      fetcher,
      estimateMinFeesWithdrawals,
      l1WithdrawalCapacities,
    ]
  );

  const createTransfer = useCallback(
    async (
      amountStr: string,
      toAccount: string,
      onMessageSigned: () => void,
      label?: string,
      referenceId?: string
    ) => {
      if (!account || !provider || !chainId || !l2Address) {
        throw Error("Unauthenticated");
      }

      const authFetcher = authApi(apiKey, apiSecret);

      // Get signing keys, and sign message
      const salt = Math.floor(Math.random() * 100000);

      const amount = ethers.utils.parseUnits(amountStr, decimals);
      const to = toAccount;

      const transferMessage: ITransferMessage = {
        collateral: l2Address,
        to,
        amount: amount.toString(),
        salt: salt.toString(),
      };

      const domainData = getDomainData(chainId);

      const providerSigner = provider.getSigner();
      /* eslint no-underscore-dangle: 0 */
      const transferSignature = await providerSigner._signTypedData(
        domainData,
        {
          Transfer: transferType,
        },
        transferMessage
      );
      onMessageSigned();

      const postBody = {
        account,
        collateral: l2Address,
        to,
        amount: amount.toString(),
        salt: String(salt),
        signature: ethers.utils.joinSignature(
          ethers.utils.splitSignature(transferSignature)
        ),
        label: label ?? "",
        referenceId: referenceId ?? "",
      };

      try {
        const response = (await (await authFetcher.postTransfer(postBody))())
          .data;
        return response;
      } catch (error: any) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error || "Error withdrawing"
        );
      }
    },
    [account, provider, chainId, l2Address, apiKey, apiSecret, decimals]
  );

  return {
    withdrawalFees,
    createWithdrawal,
    createTransfer,
    depositAsset,
  };
};

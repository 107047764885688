import { useCallback, useContext, useState } from "react";
import useSound from "use-sound";
import { SFXSettings, SettingsContext } from "../../contexts/SettingsContext";

import sfxSound from "../../assets/audio/sfx.mp3";

export interface IPlayOptions {
  id?: string;
  forceSoundEnabled?: boolean;
  playbackRate?: number;
}

export declare type PlayFunction = (options?: IPlayOptions) => void;

// Sound can only be played ever x millis
const SOUND_FX_RATE_LIMIT_MS = 300;

type ISoundToLastPlayedTimeMillis = {
  [key in SFXSettings]?: number;
};

type ISoundSpriteSettings = {
  [key in SFXSettings]: [number, number];
};

const soundSprite: ISoundSpriteSettings = {
  disable_all: [0, 0],
  error: [0, 200],
  order_cancelled: [250, 143],
  order_placed: [430, 410],
  order_filled: [780, 1050],
  points: [1850, 1550],
  yv_deposit: [3400, 400],
  infographic_transition: [3850, 3650],
  positive_game_sound: [7500, 1208],
  social_media_like: [8808, 1637],
};

export const useSFX = () => {
  let play: ReturnType<typeof useSound>[0] = () => {};

  try {
    // Only initialize sound if not in development
    if (process.env.NODE_ENV !== "development") {
      [play] = useSound(sfxSound, { sprite: soundSprite });
    }
  } catch (error) {
    // Provide a no-op function if sound fails to initialize
    play = () => {};
  }

  const [, setSoundRateLimitCounter] = useState<ISoundToLastPlayedTimeMillis>(
    {}
  );
  const { sfx: userSettings } = useContext(SettingsContext);

  const playSound = useCallback(
    (sound: SFXSettings, force?: boolean) => {
      if (process.env.NODE_ENV === "test") {
        return;
      }

      if (force || userSettings.has(sound)) {
        setSoundRateLimitCounter((prev) => {
          const lastPlayed = prev[sound];
          if (
            !lastPlayed ||
            Date.now() >= lastPlayed + SOUND_FX_RATE_LIMIT_MS
          ) {
            try {
              if (typeof play === "function") {
                play({ id: sound });
              }
            } catch (error) {
              // Silently handle errors
            }
          }

          return {
            ...prev,
            [sound]: Date.now(),
          };
        });
      }
    },
    [play, userSettings]
  );

  return {
    // Return a no-op function in development
    playSound: process.env.NODE_ENV === "test" ? () => {} : playSound,
  };
};

import styled from "styled-components";
import { getProfitTextColor } from "../../../../utils/strings";
import { TEXT_COLORS } from "../../../../constants/design/colors";
import { FONT_CSS } from "../../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../../constants/design/spacing";

export const UnrealizedPnlCard = styled.div<{
  pnl: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${BORDER_RADIUS.five}px;
  padding: ${SPACING.three}px;
  background-color: ${({ pnl }) => getProfitTextColor(pnl, false, true)};

  ${FONT_CSS.label.three}
  color: ${TEXT_COLORS.three};

  span:not(:last-child) {
    margin-bottom: ${SPACING.two}px;
  }

  span:nth-child(2) {
    ${FONT_CSS.data.one}
    color: ${({ pnl }) => getProfitTextColor(pnl)};
  }

  span:nth-child(4) {
    ${FONT_CSS.data.three}
    color: ${({ pnl }) => getProfitTextColor(pnl)};
  }
`;

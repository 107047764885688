// We use the same environment for development and staging
// But we still need a switch to only show dev features locally
export const isDevelopment = () =>
  process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "development";
export const isProductionTest = () =>
  process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "production-test";
export const isProduction = () =>
  process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "production" ||
  isProductionTest();
export const isStaging = () =>
  !isDevelopment() && !isProduction() && !isProductionTest();

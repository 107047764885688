/* eslint-disable no-nested-ternary */
import currency from "currency.js";
import { useAnimation } from "framer-motion";
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CollateralAssetResponse,
  InstrumentTypeResponse,
  OrderTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import { COLORS } from "../../../constants/design/colors";
import {
  INDEX_PRICE_COLLAR_OPTIONS,
  MARK_PRICE_COLLAR,
  MIN_ORDER_VALUE,
} from "../../../constants/precision/form";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { MarketContext } from "../../../contexts/MarketContext";
import { IOptionMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { FormValidatorKeysEnum } from "../../../enums/form";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import { useMarginRequirements } from "../../../hooks/api/margin/useMarginRequirements";
import { useOrder } from "../../../hooks/api/order/useOrder";
import { useToast } from "../../../hooks/toast";
import usePrevious from "../../../hooks/usePrevious";
import { useTickersStore } from "../../../hooks/wss/public/store/useTickersStore";
import useIndexWSS from "../../../hooks/wss/useIndexWSS";
import { useOrderbookWSS } from "../../../hooks/wss/useOrderbookWSS";
import usePositionsWSS from "../../../hooks/wss/usePositionsWSS";
import { ICreateOrderBody } from "../../../interfaces/Order";
import { IPriceSize } from "../../../interfaces/Orderbook";
import { IPositionInfo, ITradeInfo } from "../../../interfaces/TradeInfo";
import { getAssetLogo } from "../../../utils/asset/assets";
import {
  getContractPriceStep,
  getExpiryFromUnix,
} from "../../../utils/instruments";
import {
  getValueOfContractsAmount,
  maxOrderSizeForMarkPriceCollar,
} from "../../../utils/orderbook";
import {
  ToastEnum,
  ToastStatusEnum,
  getToastTitleForInstrument,
} from "../../../utils/toast";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { OptionFormFieldKeyEnum, useOptionsForm } from "./components/form";
import usePersistentState from "../../../hooks/usePersistentState";
import MobileComponent from "./MobileComponent";
import DesktopComponent from "./DesktopComponent";

export interface ITradeModalProps {
  selectedInstrument?: IOptionMarket;
  mobileMode?: boolean;
  onClose?: () => void;
  showTradeForm?: boolean;
  setShowTradeForm?: (showTradeForm: boolean) => void;
}

function OptionsTradeForm({
  selectedInstrument,
  mobileMode,
  onClose,
  showTradeForm,
  setShowTradeForm,
}: PropsWithChildren<ITradeModalProps>) {
  const { t: apiError } = useTranslation("apiErrors");
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { market } = useContext(MarketContext);
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });
  const { t: optionsTradeFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.OptionsTradeForm.OptionsTradeForm",
  });
  const { addToast, addErrorToast } = useToast();
  const { accountSigningKeyState } = useContext(AuthContext);

  const orderbookData = useOrderbookWSS(selectedInstrument?.instrument_name);

  const { index } = useIndexWSS(selectedInstrument?.underlying_asset);

  const prevOrderbookInstrument = usePrevious(orderbookData?.instrument_name);
  const animControls = useAnimation();

  // STATES
  const [reduceOnly, setReduceOnly] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultValuesTriggered, setDefaultValuesTriggered] =
    useState<boolean>(false);
  const { formDefaults, setFormDefaults } = usePersistentState();
  const [orderSide, setOrderSide] = useMemo(() => {
    const set = (side: SideResponse) => setFormDefaults({ side });
    if (formDefaults?.side) {
      return [formDefaults.side, set];
    }
    return [SideResponse.Buy, set];
  }, [formDefaults?.side, setFormDefaults]);
  const [orderType, setOrderType] = useMemo(() => {
    const set = (type: OrderTypeResponse) =>
      setFormDefaults({ orderType: type });
    if (formDefaults?.orderType) {
      return [formDefaults.orderType, set];
    }
    return [OrderTypeResponse.Market, set];
  }, [formDefaults?.orderType, setFormDefaults]);

  const modalRef = useRef<HTMLDivElement>(null);

  const optionsForm = useOptionsForm();
  const {
    formState: { errors, isDirty },
    setValue,
    control,
    trigger,
  } = optionsForm;

  const amount = useWatch({ control, name: "amount" });
  const price = useWatch({ control, name: "price" });

  const { data: orderData, createOrder } = useOrder();
  const { data: accountData, isLoading: accountDataLoading } = useGetAccount();
  const { positionsMap } = usePositionsWSS(selectedInstrument?.instrument_name);

  const { data: marginData } = useMarginRequirements(
    // Only sell have margin requirements
    orderSide === SideResponse.Sell &&
      amount &&
      selectedInstrument?.instrument_id
      ? {
          is_buy: false,
          limit_price:
            orderType === OrderTypeResponse.Limit
              ? String(Math.round(Number(price) || 0))
              : "0",
          amount,
          instrument_id: Number(selectedInstrument.instrument_id),
        }
      : undefined
  );

  const ticker = useTickersStore((state) => {
    if (selectedInstrument) {
      return state.ticker[
        getExpiryFromUnix(Number(selectedInstrument.expiry)) || ""
      ]?.[selectedInstrument.instrument_name];
    }
    return undefined;
  });

  const [markPrice, greeks] = [ticker?.mark.price, ticker?.mark.greeks];

  // UseEffects

  // Also trigger updates whenever price is changed
  useEffect(() => {
    if (amount && price) {
      trigger(OptionFormFieldKeyEnum.AMOUNT);
      trigger(OptionFormFieldKeyEnum.PRICE);
    }
  }, [price, amount, trigger, marginData]);

  // Update whenever variables change
  useEffect(() => {
    if (isDirty && amount) {
      trigger(OptionFormFieldKeyEnum.AMOUNT);
    }
  }, [amount, isDirty, reduceOnly, trigger, orderSide, orderType]);

  // Limit triggers
  useEffect(() => {
    if (price && amount) {
      trigger(OptionFormFieldKeyEnum.PRICE);
      trigger(OptionFormFieldKeyEnum.AMOUNT);
    }
  }, [amount, price, trigger]);

  // after order type set to limit with default values
  // set the default values to form
  useEffect(() => {
    if (defaultValuesTriggered) {
      if (amount) {
        setValue(OptionFormFieldKeyEnum.AMOUNT, amount, {
          shouldValidate: true,
        });
      }
      if (price) {
        setValue(OptionFormFieldKeyEnum.PRICE, price, {
          shouldValidate: true,
        });
      }
    }
    // only call this when defaultValuesTriggeredChanges
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValuesTriggered, setValue]);

  // Update default price for limit order
  useEffect(() => {
    // We're comparing the current value and the previous one to prevent the UI from
    // resetting the price/amount values in between renders
    if (
      orderbookData?.instrument_name &&
      prevOrderbookInstrument !== orderbookData?.instrument_name
    ) {
      // Update default values for the selected instrument
      let defaultPrice = "0";
      defaultPrice =
        orderSide === SideResponse.Buy
          ? orderbookData?.bids?.[0]?.[0] || "0"
          : orderbookData?.asks?.[0]?.[0] || "0";

      if (Number(defaultPrice)) {
        setValue(OptionFormFieldKeyEnum.PRICE, defaultPrice);
      } else {
        setValue(OptionFormFieldKeyEnum.PRICE, "");
      }
    }
  }, [orderSide, orderbookData, prevOrderbookInstrument, setValue]);

  const contractPriceStep = useMemo(
    () => getContractPriceStep(selectedInstrument),
    [selectedInstrument]
  );

  const currentPosition = useMemo(
    () =>
      selectedInstrument
        ? positionsMap[selectedInstrument.instrument_name]
        : undefined,
    [positionsMap, selectedInstrument]
  );

  // Utility callback to use when verifying input
  const calculateTotalValueWithSize = useCallback(
    (amountStr: string) => {
      let total = 0;
      if (orderType === OrderTypeResponse.Market) {
        total = getValueOfContractsAmount(
          orderSide,
          orderSide === SideResponse.Buy
            ? (orderbookData?.asks as IPriceSize[]) || []
            : (orderbookData?.bids as IPriceSize[]) || [],
          Number(amountStr)
        );
      } else {
        total = amountStr ? Number(amountStr) * Number(price) : total;
      }
      return total;
    },
    [orderSide, orderType, orderbookData, price]
  );

  const feeStructure = accountData?.fee_structures?.find(
    (f) => f.asset === market.asset && f.instrument_type === market.derivative
  );

  // Reduce only is disabled if ordertype is limit
  const reduceOnlyDisabled = orderType === OrderTypeResponse.Limit;

  // Total value in USD
  const totalValue = useMemo(
    () => calculateTotalValueWithSize(amount),
    [calculateTotalValueWithSize, amount]
  );

  const orderValueTooSmall = useMemo(() => {
    if (errors?.amount?.type === FormValidatorKeysEnum.orderValueTooSmall) {
      return true;
    }
    return false;
  }, [errors?.amount?.type]);

  const notEnoughBalanceError = useMemo(() => {
    if (errors?.amount?.type === FormValidatorKeysEnum.notEnoughBalance) {
      return true;
    }
    return false;
  }, [errors?.amount?.type]);

  // Reduce only is not allowed if theres no position, or position === orderDirection
  const reduceOnlyNotAllowed = useMemo(
    () =>
      !reduceOnlyDisabled &&
      (!currentPosition || currentPosition.side === orderSide),
    [currentPosition, orderSide, reduceOnlyDisabled]
  );

  // INFOS

  const tradeInfo = useMemo<ITradeInfo[]>(() => {
    const totalInfo: ITradeInfo = {
      title: optionsTradeFormTranslations("total"),
      value: totalValue ? currency(totalValue).format() : "---",
      warningOrError:
        notEnoughBalanceError || orderValueTooSmall ? "error" : undefined,
    };

    const premiumsInfo: ITradeInfo = {
      title: optionsTradeFormTranslations("premiums_earned"),
      value: totalValue ? currency(totalValue).format() : "---",
    };

    const marginRequiredInfo: ITradeInfo = {
      title: optionsTradeFormTranslations("margin_required"),
      value: marginData?.initial_margin
        ? currency(marginData?.initial_margin).format()
        : "---",
      warningOrError:
        notEnoughBalanceError || orderValueTooSmall ? "error" : undefined,
    };

    // ================ MARKET ORDERS ================
    if (orderType === OrderTypeResponse.Market) {
      // MARKET BUY/SELL ORDER
      if (orderSide === SideResponse.Buy) {
        return [totalInfo];
      }
      return [premiumsInfo, marginRequiredInfo];
    }

    // ================ LIMIT ORDERS ================
    if (orderSide === SideResponse.Buy) {
      // LIMIT BUY ORDER
      return [totalInfo];
    }
    // LIMIT SELL ORDER
    return [premiumsInfo, marginRequiredInfo];
  }, [
    optionsTradeFormTranslations,
    orderType,
    orderSide,
    totalValue,
    notEnoughBalanceError,
    marginData,
    orderValueTooSmall,
  ]);

  const positionInfo = useMemo<IPositionInfo[]>(() => {
    // Options available balance is ONLY using USDC available balance
    const optionsAvailableBalance = accountData?.collaterals?.find(
      (c) => c.collateral_asset === CollateralAssetResponse.Usdc
    )?.available_balance;

    const balanceInfo: IPositionInfo =
      orderSide === SideResponse.Buy
        ? {
            title: optionsTradeFormTranslations("available_margin"),
            value:
              accountDataLoading || !optionsAvailableBalance
                ? "---"
                : currency(optionsAvailableBalance).format(),
            side: undefined,
            warningOrError: notEnoughBalanceError ? "error" : undefined,
            showErrorIcon: true,
          }
        : {
            title: commonFormTranslations("margin_balance"),
            value: accountData?.available_margin
              ? currency(accountData.available_margin).format()
              : "---",
            side: undefined,
            warningOrError: notEnoughBalanceError ? "error" : undefined,
            showErrorIcon: true,
          };

    const posInfo: IPositionInfo[] = [
      balanceInfo,
      {
        title: optionsTradeFormTranslations("current_position"),
        value: currentPosition
          ? Number(currentPosition.amount).toFixed(
              contractPriceStep.amount_precision
            )
          : "---",
        side: currentPosition?.side,
      },
    ];

    if (currentPosition) {
      const roi =
        Number(currentPosition.unrealized_pnl) /
        (Number(currentPosition.avg_entry_price) *
          Number(currentPosition.amount));
      posInfo.push({
        title: optionsTradeFormTranslations("position_roi"),
        value: currentPosition ? (
          <span
            style={{
              color:
                Number(currentPosition.unrealized_pnl) >= 0
                  ? COLORS.positive.one
                  : COLORS.negative.one,
            }}
          >
            {roi >= 0 ? "+" : ""}
            {(roi * 100).toFixed(2)}%
          </span>
        ) : (
          "---"
        ),
      });
    }

    return posInfo;
  }, [
    commonFormTranslations,
    optionsTradeFormTranslations,
    accountDataLoading,
    accountData?.collaterals,
    accountData?.available_margin,
    contractPriceStep.amount_precision,
    notEnoughBalanceError,
    currentPosition,
    orderSide,
  ]);

  // Validates available liquidity for market buy/sell
  const insufficientMarketLiquidityWarning = useMemo(() => {
    if (!selectedInstrument || orderType === OrderTypeResponse.Limit) {
      return undefined;
    }

    // if buy, check asks liquidity
    let insufficientLiquidity = false;
    if (orderSide === SideResponse.Buy && orderbookData) {
      const totalAsksSize =
        orderbookData.asks?.reduce((prev, ask) => {
          const askSize = Number(ask[1] || 0);
          return prev + askSize;
        }, 0) || 0;
      if (totalAsksSize < parseFloat(amount)) {
        insufficientLiquidity = true;
      }
    }

    // if sell, check bids liquidity
    if (orderSide === SideResponse.Sell && orderbookData) {
      const totalBidsSize =
        orderbookData.bids?.reduce((prev, ask) => {
          const bidSize = Number(ask[1] || 0);
          return prev + bidSize;
        }, 0) || 0;
      if (totalBidsSize < parseFloat(amount)) {
        insufficientLiquidity = true;
      }
    }

    if (insufficientLiquidity) {
      return optionsTradeFormTranslations("insufficient_liquidity");
    }
    return undefined;
  }, [
    selectedInstrument,
    orderType,
    orderSide,
    orderbookData,
    amount,
    optionsTradeFormTranslations,
  ]);

  const inactiveSubmitButtonState = useMemo(
    () => (
      <Button
        fullWidth
        type="button"
        buttonTheme={ButtonThemeEnum.HIGHLIGHT}
        onClick={() => setShowConnectModal(true)}
      >
        {accountSigningKeyState === AccountStateEnum.REQUIRE_PASSWORD
          ? commonFormTranslations("unlock_trading")
          : accountSigningKeyState === AccountStateEnum.OK
          ? commonFormTranslations("continue_onboarding")
          : accountSigningKeyState === AccountStateEnum.REQUIRE_CONNECT
          ? commonFormTranslations("connect_wallet")
          : commonFormTranslations("complete_sign_in")}
      </Button>
    ),
    [accountSigningKeyState, commonFormTranslations, setShowConnectModal]
  );

  const showOrderCreatedAnimation = useCallback(async () => {
    await animControls.start({
      opacity: 1,
      scale: 1,
      transition: { duration: 0.6 },
    });
    await animControls.start({
      opacity: 0,
      scale: 0,
      transition: {
        delay: 3,
        duration: 0.5,
      },
    });
  }, [animControls]);

  const submitOrder = useCallback(async () => {
    if (selectedInstrument && Number(amount)) {
      const order: ICreateOrderBody =
        orderType === OrderTypeResponse.Market
          ? {
              amount,
              side: orderSide,
              instrument: Number(selectedInstrument.instrument_id),
              orderType: OrderTypeResponse.Market,
              stop: undefined,
              trigger: undefined,
              reduceOnly,
            }
          : {
              amount,
              side: orderSide,
              instrument: Number(selectedInstrument.instrument_id),
              orderType: OrderTypeResponse.Limit,
              price: String(price),
              stop: undefined,
              trigger: undefined,
            };

      const toastInterval = 4000;

      try {
        setIsLoading(true);
        const response = await createOrder(order);

        if (response.order_id) {
          // Only show immediate toast if is market order.
          // Else just show order placed
          if (orderType === OrderTypeResponse.Market) {
            addToast(
              {
                type: ToastEnum.INFO,
                icon: getAssetLogo(market.asset) as string,
                header: (
                  <p>
                    {getToastTitleForInstrument(
                      InstrumentTypeResponse.Option,
                      selectedInstrument.instrument_name,
                      Number(selectedInstrument.expiry),
                      selectedInstrument.strike
                    )}
                  </p>
                ),
                subheader:
                  orderSide === SideResponse.Buy ? (
                    <span style={{ color: COLORS.positive.one }}>
                      {optionsTradeFormTranslations("market_buy")}
                    </span>
                  ) : (
                    <span style={{ color: COLORS.negative.one }}>
                      {optionsTradeFormTranslations("market_sell")}
                    </span>
                  ),
                stats: [
                  {
                    label:
                      orderSide === SideResponse.Buy
                        ? commonFormTranslations("buy_amount")
                        : commonFormTranslations("sell_amount"),
                    value: (
                      <span
                        style={{
                          color:
                            orderSide === SideResponse.Buy
                              ? COLORS.positive.one
                              : COLORS.negative.one,
                        }}
                      >
                        {Number(response.filled).toFixed(
                          contractPriceStep.amount_precision
                        ) || "0.00"}
                      </span>
                    ),
                  },
                  {
                    label: optionsTradeFormTranslations("avg_price"),
                    value: currency(response.avg_price || 0).format(),
                  },
                ],
                status: ToastStatusEnum.SUCCESS,
              },
              toastInterval
            );
          } else {
            addToast(
              {
                type: ToastEnum.INFO,
                icon: getAssetLogo(market.asset) as string,
                header: (
                  <p>
                    {getToastTitleForInstrument(
                      InstrumentTypeResponse.Option,
                      selectedInstrument.instrument_name,
                      Number(selectedInstrument.expiry),
                      selectedInstrument.strike
                    )}
                  </p>
                ),
                subheader:
                  orderSide === SideResponse.Buy ? (
                    <span style={{ color: COLORS.positive.one }}>
                      {optionsTradeFormTranslations("bid_placed")}
                    </span>
                  ) : (
                    <span style={{ color: COLORS.negative.one }}>
                      {optionsTradeFormTranslations("offer_placed")}
                    </span>
                  ),
                stats: [
                  {
                    label: commonFormTranslations("contracts"),
                    value: Number(order.amount).toFixed(
                      contractPriceStep.amount_precision
                    ),
                  },
                  {
                    label: commonFormTranslations("limit_price"),
                    value: currency(order.price || 0).format(),
                  },
                ],
                status: ToastStatusEnum.SUCCESS,
              },
              toastInterval
            );
          }
          showOrderCreatedAnimation();
          setValue(OptionFormFieldKeyEnum.AMOUNT, "", {
            shouldValidate: true,
          });
          onClose?.();
        }
      } catch (error: any) {
        let errorTitle = "";
        if (orderType === OrderTypeResponse.Market) {
          errorTitle =
            orderSide === SideResponse.Buy
              ? commonFormTranslations("market_buy_failed")
              : commonFormTranslations("market_sell_failed");
        } else {
          errorTitle =
            orderSide === SideResponse.Buy
              ? commonFormTranslations("limit_buy_failed")
              : commonFormTranslations("limit_sell_failed");
        }
        addErrorToast(
          <p>{errorTitle}</p>,
          apiError(error.message) || commonFormTranslations("place_order_again")
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    selectedInstrument,
    amount,
    orderType,
    orderSide,
    reduceOnly,
    price,
    createOrder,
    showOrderCreatedAnimation,
    setValue,
    onClose,
    addToast,
    market.asset,
    optionsTradeFormTranslations,
    commonFormTranslations,
    contractPriceStep.amount_precision,
    addErrorToast,
    apiError,
  ]);

  const onRowClick = useCallback(
    (p: string) => {
      setOrderType(OrderTypeResponse.Limit);
      setValue(OptionFormFieldKeyEnum.PRICE, p, { shouldValidate: true });
      setDefaultValuesTriggered(true);
      // When the price is adjusted, modal scrolls back to the top
      if (modalRef.current) {
        modalRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    [setOrderType, setValue]
  );

  const verifyOrderValueTooSmall = useCallback(
    (contractSize: string) => {
      if (orderType === OrderTypeResponse.Limit && price && contractSize) {
        return (
          Number(contractSize || 0) * Number(price || 0) >= MIN_ORDER_VALUE
        );
      }
      return true;
    },
    [orderType, price]
  );

  const verifyNotEnoughBalance = useCallback(
    (size: string) => {
      if (
        currentPosition &&
        currentPosition.side !== orderSide &&
        Number(currentPosition.amount) >= Number(size)
      ) {
        return true;
      }

      if (orderSide === SideResponse.Sell && marginData) {
        return (
          Number(marginData.initial_margin) <=
          Number(accountData?.available_margin || 0)
        );
      }
      return accountData && size
        ? Number(accountData.balance) >= calculateTotalValueWithSize(size)
        : true;
    },
    [
      accountData,
      calculateTotalValueWithSize,
      currentPosition,
      marginData,
      orderSide,
    ]
  );

  const maxOrderSizeBeforeOBProtectionTriggered =
    orderType === OrderTypeResponse.Market &&
    market.derivative !== InstrumentTypeResponse.Perpetual &&
    !insufficientMarketLiquidityWarning
      ? maxOrderSizeForMarkPriceCollar(
          orderSide,
          orderbookData?.bids || [],
          orderbookData?.asks || [],
          Number(markPrice || 0),
          Number(index?.price || 0),
          MARK_PRICE_COLLAR,
          INDEX_PRICE_COLLAR_OPTIONS
        )
      : Number.MAX_VALUE;

  if (mobileMode && setShowTradeForm) {
    return (
      <MobileComponent
        selectedInstrument={selectedInstrument}
        showTradeForm={showTradeForm}
        setShowTradeForm={setShowTradeForm}
        optionsForm={optionsForm}
        submitOrder={submitOrder}
        market={market}
        orderSide={orderSide}
        totalValue={totalValue}
        amount={amount}
        orderData={orderData}
        orderbookData={orderbookData}
        greeks={greeks}
        onRowClick={onRowClick}
        index={index}
        setOrderSide={setOrderSide}
        orderType={orderType}
        setOrderType={setOrderType}
        reduceOnlyDisabled={reduceOnlyDisabled}
        reduceOnlyNotAllowed={reduceOnlyNotAllowed}
        reduceOnly={reduceOnly}
        currentPosition={currentPosition}
        contractPriceStep={contractPriceStep}
        verifyNotEnoughBalance={verifyNotEnoughBalance}
        verifyOrderValueTooSmall={verifyOrderValueTooSmall}
        isLoading={isLoading}
        maxOrderSizeBeforeOBProtectionTriggered={
          maxOrderSizeBeforeOBProtectionTriggered
        }
        setReduceOnly={setReduceOnly}
        tradeInfo={tradeInfo}
        feeStructure={feeStructure}
        positionInfo={positionInfo}
        accountSigningKeyState={accountSigningKeyState}
        inactiveSubmitButtonState={inactiveSubmitButtonState}
        animControls={animControls}
        markPrice={markPrice}
      />
    );
  }
  return (
    <DesktopComponent
      modalRef={modalRef}
      selectedInstrument={selectedInstrument}
      optionsForm={optionsForm}
      submitOrder={submitOrder}
      market={market}
      orderSide={orderSide}
      markPrice={markPrice}
      totalValue={totalValue}
      amount={amount}
      orderData={orderData}
      orderbookData={orderbookData}
      greeks={greeks}
      onRowClick={onRowClick}
      index={index}
      setOrderSide={setOrderSide}
      orderType={orderType}
      setOrderType={setOrderType}
      reduceOnlyDisabled={reduceOnlyDisabled}
      reduceOnlyNotAllowed={reduceOnlyNotAllowed}
      reduceOnly={reduceOnly}
      currentPosition={currentPosition}
      contractPriceStep={contractPriceStep}
      verifyNotEnoughBalance={verifyNotEnoughBalance}
      verifyOrderValueTooSmall={verifyOrderValueTooSmall}
      isLoading={isLoading}
      maxOrderSizeBeforeOBProtectionTriggered={
        maxOrderSizeBeforeOBProtectionTriggered
      }
      setReduceOnly={setReduceOnly}
      tradeInfo={tradeInfo}
      feeStructure={feeStructure}
      positionInfo={positionInfo}
      accountSigningKeyState={accountSigningKeyState}
      inactiveSubmitButtonState={inactiveSubmitButtonState}
      animControls={animControls}
    />
  );
}

export default OptionsTradeForm;
